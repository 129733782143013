/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable padding-line-between-statements */
/* eslint-disable consistent-return */
// Code copied from ELF github
import { StateStorage } from '@ngneat/elf-persist-state';
import { of as just } from 'rxjs';

function createStorage(storage: Storage | undefined): StateStorage | undefined {
  if (!storage) {
    return;
  }
  return {
    getItem(key: string) {
      const v = storage.getItem(key);
      return just(v ? JSON.parse(v, reviver) : v);
    },
    setItem(key: string, value: Record<string, any>) {
      storage.setItem(key, JSON.stringify(value, replacer));
      return just(true);
    },
    removeItem(key: string) {
      storage.removeItem(key);
      return just(true);
    },
  };
}

// we need to wrap the access to window.localStorage and window.sessionStorage in a try catch
// because localStorage can be disabled, or be denied by a security rule
// as soon as we access the property, it throws an error
const tryGetLocalStorage = () => {
  try {
    if (typeof localStorage !== 'undefined') {
      return localStorage;
    }
  } catch {
    // eslint-disable-next-line no-empty
  }
  return undefined;
};

export const customLocalStorageStrategy = createStorage(tryGetLocalStorage())!;

const tryGetSessionStorage = () => {
  try {
    if (typeof sessionStorage !== 'undefined') {
      return sessionStorage;
    }
  } catch {
    // eslint-disable-next-line no-empty
  }
  return undefined;
};

export const customSessionStorageStrategy = createStorage(tryGetSessionStorage())!;

function replacer(_key: any, value: any) {
  if (value instanceof Map) {
    return { __type: 'Map', value: Object.fromEntries(value) };
  }
  if (value instanceof Set) {
    return { __type: 'Set', value: Array.from(value) };
  }
  return value;
}

function reviver(_key: any, value: any) {
  if (value?.__type === 'Set') {
    return new Set(value.value);
  }
  if (value?.__type === 'Map') {
    return new Map(Object.entries(value.value));
  }
  return value;
}
