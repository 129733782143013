import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { persistState } from '@ngneat/elf-persist-state';
import {
  clearRequestsStatus,
  createRequestsStatusOperator,
  selectIsRequestPending,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { Observable } from 'rxjs';
import { customSessionStorageStrategy } from '@app-shared/functions/custom-storage-strategies';

import { AnswerValue, AssessmentData, AssessmentField } from '../interfaces/assessment.model';

type AssessmentAnswers = Map<AssessmentField['field_key'], AnswerValue>;

interface AssessmentNavigation {
  previousFieldIndex: number | null;
  currentFieldIndex: number;
  nextFieldIndex: number | null;
  lastUnansweredFieldIndex: number | null;
}
interface AssessmentStoreProps {
  assessment: AssessmentData | null;
  currentField: AssessmentField | null;
  answers: AssessmentAnswers;
  navigation: AssessmentNavigation;
  lastFieldIndex: number;
  assessmentReadyForSubmit: boolean;
  isAnonAssessment: boolean;
}

const initialApplicationStore: AssessmentStoreProps = {
  assessment: null,
  currentField: null,
  answers: new Map(),
  navigation: {
    previousFieldIndex: null,
    currentFieldIndex: 0,
    nextFieldIndex: null,
    lastUnansweredFieldIndex: null,
  },
  lastFieldIndex: 0,
  assessmentReadyForSubmit: false,
  isAnonAssessment: false,
};

@Injectable({ providedIn: 'root' })
export class AssessmentRepository {
  private readonly store;

  public trackAssessmentRequestStatus;

  assessmentRequestLoading$: Observable<boolean>;

  assessment$: Observable<AssessmentStoreProps['assessment']>;
  currentField$: Observable<AssessmentStoreProps['currentField']>;
  navigation$: Observable<AssessmentStoreProps['navigation']>;
  assessmentReadyForSubmit$: Observable<AssessmentStoreProps['assessmentReadyForSubmit']>;
  answers$: Observable<AssessmentStoreProps['answers']>;

  constructor() {
    this.store = createStore(
      { name: 'assessment-data' },
      withProps<AssessmentStoreProps>(initialApplicationStore),
      withRequestsStatus<'assessment'>(),
    );

    this.trackAssessmentRequestStatus = createRequestsStatusOperator(this.store);

    // store select value > returns observable
    this.assessment$ = this.store.pipe(select(state => state.assessment));

    this.currentField$ = this.store.pipe(select(state => state.currentField));

    this.assessmentReadyForSubmit$ = this.store.pipe(select(state => state.assessmentReadyForSubmit));

    this.navigation$ = this.store.pipe(select(state => state.navigation));

    this.answers$ = this.store.pipe(select(state => state.answers));

    // loading states
    this.assessmentRequestLoading$ = this.store.pipe(selectIsRequestPending('assessment'));

    persistState(this.store, {
      key: 'assessment-data',
      storage: customSessionStorageStrategy,
    });
  }

  updateAssessmentData(assessment: AssessmentData): void {
    const updatedResponse = this.addAssessmentFieldsIndex(assessment);

    this.store.update(setProp('assessment', updatedResponse));
    this.store.update(setProp('lastFieldIndex', assessment.result.quiz.fields.length - 1));

    this.setAnsweredFieldsData(assessment.result_answers);

    this.setCurrentField(assessment.last_answer);
  }

  updateAssessmentNavigationData(currentFieldIndex: number): void {
    const navigation: AssessmentNavigation = {
      ...this.store.getValue().navigation,
      previousFieldIndex: currentFieldIndex ? currentFieldIndex - 1 : null,
      currentFieldIndex: currentFieldIndex,
      nextFieldIndex: currentFieldIndex + 1 > this.getLastFieldIndexData() ? null : currentFieldIndex + 1,
    };
    this.store.update(setProp('navigation', navigation));
  }

  updateLastUnansweredFieldIndex(nextFieldIndex: number): void {
    const navigation: AssessmentNavigation = {
      ...this.store.getValue().navigation,
      lastUnansweredFieldIndex: nextFieldIndex,
    };

    this.store.update(setProp('navigation', navigation));
  }

  updateCurrentFieldData(currentQuestionIndex: number): void {
    const currentQuestion = this.store.getValue().assessment?.result.quiz.fields[currentQuestionIndex];
    this.store.update(setProp('currentField', currentQuestion ?? null));
  }

  updateAnsweredQuestionsData(fieldKey: AssessmentField['field_key'], answerValue: AnswerValue): void {
    const assessmentAnswers = this.store.getValue().answers;
    assessmentAnswers.set(fieldKey, answerValue);
    this.store.update(setProp('answers', assessmentAnswers));
  }

  updateAssessmentReadyForSubmitData(assessmentReadyForSubmit: boolean): void {
    this.store.update(setProp('assessmentReadyForSubmit', assessmentReadyForSubmit));
  }

  private addAssessmentFieldsIndex(assessmentData: AssessmentData): AssessmentData {
    const fieldsWithAddedIndex = assessmentData.result.quiz.fields.map((field, index) => {
      return { ...field, index: index };
    });

    assessmentData.result.quiz.fields = fieldsWithAddedIndex;

    return assessmentData;
  }

  setCurrentField(lastAnswerKey: string | null): void {
    // handle current question on update assessment data
    if (lastAnswerKey) {
      // get last answer index
      const lastAnswerIndex = this.store
        .getValue()
        .assessment?.result.quiz.fields.findIndex(item => item.field_key === lastAnswerKey);

      if (lastAnswerIndex && lastAnswerIndex < this.getLastFieldIndexData()) {
        // if last visited filed index is smaller then last field index update current field data with next field
        const nextFieldIndex = lastAnswerIndex + 1;
        this.updateCurrentFieldData(nextFieldIndex);
        this.updateAssessmentNavigationData(nextFieldIndex);
        this.updateLastUnansweredFieldIndex(nextFieldIndex);
      } else {
        // set current field to null
        this.store.update(setProp('currentField', null));
        // set assessment as ready for submit
        this.updateAssessmentReadyForSubmitData(true);
      }
    } else {
      // set first filed as current field
      this.updateCurrentFieldData(0);
      this.updateAssessmentNavigationData(0);
      this.updateLastUnansweredFieldIndex(0);
    }
  }

  setAnsweredFieldsData(answers: AssessmentData['result_answers']): void {
    this.store.update(setProp('answers', new Map()));
    // add already answered questions to answers map, if they exist
    if (Array.isArray(answers)) {
      return;
    } else {
      Object.keys(answers).forEach(key => {
        this.updateAnsweredQuestionsData(key, answers[key]);
      });
    }
  }

  // anon assessment
  updatedAssessmentAnonStatus(isAnon: boolean): void {
    this.store.update(setProp('isAnonAssessment', isAnon));
  }

  // store get value once

  getAssessmentData(): AssessmentStoreProps['assessment'] {
    return this.store.getValue().assessment;
  }

  getAssessmentAnswersData(): AssessmentStoreProps['answers'] {
    return this.store.getValue().answers;
  }

  getLastFieldIndexData(): AssessmentStoreProps['lastFieldIndex'] {
    return this.store.getValue().lastFieldIndex;
  }

  getCurrentFieldData(): AssessmentStoreProps['currentField'] {
    return this.store.getValue().currentField;
  }

  getAssessmentReadyForSubmitData(): AssessmentStoreProps['assessmentReadyForSubmit'] {
    return this.store.getValue().assessmentReadyForSubmit;
  }

  getAssessmentFieldByIndex(fieldIndex: number): AssessmentField | null {
    return this.store.getValue().assessment?.result.quiz.fields.find(field => field.index === fieldIndex) ?? null;
  }

  // anon assessment
  isAnonAssessment(): AssessmentStoreProps['isAnonAssessment'] {
    return this.store.getValue().isAnonAssessment;
  }

  // clear
  clearSession(): void {
    this.store.reset();
  }

  clearRequestStatus(): void {
    this.store.update(clearRequestsStatus());
  }
}
